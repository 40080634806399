<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->

    <div>
      
      <b-row v-if="checkPermission($route.name, 'Report Analytics') == true">
        <b-col md="6">

          <b-card no-body class="custom-card-height">
          
            <b-card-body>
              
                  
                  <b-row class="mb-2">
                    <b-col cols="12" md="12" class="mb-2">
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sites"
                        label="site_name"
                        class="w-100"
                        placeholder="Project Site"
                        @input="resetData"
                        v-model="siteData"
                        :clearable="false"
                      />
                    </b-col>

                    <b-col cols="12" md="12" class="mb-2">
                      <v-select
                          v-model="locations"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          label="name"
                          :options="alllocations"
                          placeholder="Select Locations"
                          @input="filterTable"
                          :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                        />
                    </b-col>
                    
                    

                    <b-col cols="12" md="12" class="mb-2">
                      <b-form-select v-model="status" @change="filterTable">
                          <b-form-select-option value="" disabled>Status</b-form-select-option>
                          <b-form-select-option value="open" >Open</b-form-select-option>
                          <b-form-select-option value="in_progress" >In Progress</b-form-select-option>
                          <b-form-select-option value="validate" >Validate</b-form-select-option>
                          <b-form-select-option value="closed" >Closed</b-form-select-option>
                          <b-form-select-option value="failed" >Failed</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" class="mb-2">
                      <b-form-datepicker
                        v-model="start"
                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                        locale="en" @input="updateStart()"
                      />
                    </b-col>

                    <b-col cols="12" md="6" class="mb-2">
                      <b-form-datepicker 
                        v-model="end"
                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                        locale="en" @input="filterTable" :min="disabledDates()"
                      />
                    </b-col>

                    <b-col cols="12" md="6" class="mb-2">
                      <b-button
                          variant="danger"
                          class="mt-0"
                          @click="resetall"
                        >
                        <span class="text-nowrap">Reset</span>
                      </b-button>

                    </b-col> 
                  </b-row>
                
            </b-card-body>
          </b-card>
        </b-col>

        <b-col md="3">

          <b-card no-body class="custom-card-height">
            <b-card-body>
              
              <!-- apex chart -->
              <vue-apex-charts
                type="pie"
                height="200"
                class="mt-0 mb-1"
                :options="customersPie.chartOptions"
                :series="series"
              />

              <!-- list group -->
              <div class="pt-25">
                <div
                  v-for="(data,index) in chartData.listData"
                  :key="index"
                  class="d-flex justify-content-between"
                  :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                >
                  <div class="series-info">
                    <feather-icon
                      :icon="data.icon"
                      size="16"
                      class="mr-50"
                      :class="data.iconColor"
                    />
                    <span class="font-weight-bolder">{{ data.text }}</span>
                  </div>
                  <span>{{ data.result }}</span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col md="3">

          <b-card no-body class="custom-card-height">
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-md-0 mb-2 margin_bottom_zero_mobile">
                  <h5 class="margin_bottom_zero_mobile">Task Summary</h5>

                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col md="12">
                  <div class="pt-25">
                    <div
                      v-for="(data,index) in chartData.summaryData"
                      :key="index"
                      class="d-flex justify-content-between"
                      :class="index === Object.keys(chartData.summaryData).length - 1 ? '':'mb-1'"
                    >
                      <div class="series-info">
                        <feather-icon
                          :icon="data.icon"
                          size="16"
                          class="mr-50"
                          :class="data.iconColor"
                        />
                        <span class="font-weight-bolder">{{ data.text }}</span>
                      </div>
                      <span>{{ data.result }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body v-if="checkPermission($route.name, 'Report Analytics') == false">
        <b-card-body>
          <b-row>

            <b-col cols="12" md="4" class="mb-2">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sites"
                label="site_name"
                class="w-100"
                placeholder="Project Site"
                @input="resetData"
                v-model="siteData"
                :clearable="false"
              />
            </b-col>

            <b-col cols="12" md="4" class="mb-2">
              <v-select
                  v-model="locations"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="alllocations"
                  placeholder="Select Locations"
                  @input="filterTable"
                  :close-on-select=false
                            :clear-on-select=false
                />
            </b-col>

            <b-col cols="12" md="4" class="mb-2">
              <b-form-select v-model="status" @change="filterTable">
                  <b-form-select-option value="" disabled>Status</b-form-select-option>
                  <b-form-select-option value="open" >Open</b-form-select-option>
                  <b-form-select-option value="in_progress" >In Progress</b-form-select-option>
                  <b-form-select-option value="validate" >Validate</b-form-select-option>
                  <b-form-select-option value="closed" >Closed</b-form-select-option>
                  <b-form-select-option value="failed" >Failed</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mb-2">
              <b-form-datepicker
                v-model="start"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en" @input="updateStart()"
              />
            </b-col>

            <b-col cols="12" md="4" class="mb-2">
              <b-form-datepicker 
                v-model="end"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en" @input="filterTable" :min="disabledDates()"
              />
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
                placeholder="Search..."
                @input="filterTable"
              />
                <!-- v-if="checkPermission($route.name, 'Pdf')" -->
              <b-button
                :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                variant="success"
                class="mr-1 mobile-margin-bottom"
                @click="openPDFPopUp()"
              >
                <span class="text-nowrap">PDF</span>
              </b-button>

                <!-- v-if="checkPermission($route.name, 'Excel')" -->
              <b-button
                :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                variant="secondary"
                class="mr-1 mobile-margin-bottom"
                @click="download('excel')"
              >
                <span class="text-nowrap">Excel</span>
              </b-button>

                <!-- v-if="checkPermission($route.name, 'Delete')" -->
              <b-button
                :disabled="siteData != null && siteData._id != 'all-site'  ? false:true"
                variant="danger"
                class="mr-1 mobile-margin-bottom"
                @click="changeMultipleStatus('deleted')"
              >
                <span class="text-nowrap">Delete</span>
              </b-button>

                <!-- v-if="checkPermission($route.name, 'Zip Download')" -->
              <b-button
                :disabled="siteData != null && siteData._id != 'all-site'  ? false:true"
                variant="primary"
                class="mr-1 mobile-margin-bottom"
                @click="zipDownload()"
              >
                <span class="text-nowrap">Zip Download</span>
              </b-button>

              


            </div>




          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative bigTable common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-warning p-0" v-model="selectAll" @change="selectall"/>
        </template>

        <template #cell(checkbox)="items">
           
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />
         
        </template>

        

        <!-- Column: User -->

         <template #cell(info)="items">             
							<feather-icon
							:icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
							@click="items.toggleDetails"
							/>
				</template>

        <template #cell(unique_id)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.unique_id | capitalize}}</span>
         
        </template>

        <!-- <template #cell(title)="items">
           
            <span class="wordBreak">{{ items.item.title | capitalize}}</span>
         
        </template> -->


        <!-- <template #cell(crew)="items">
           
            <span class="wordBreak">{{ items.item.crew | crewArray}}</span>
            
         
        </template> -->

        <!-- <template #cell(completed_by)="items">
            <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
            <span v-if="items.item.completed_by == null">N/A</span>
        </template> -->


        <template #cell(site)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site.site_name | capitalize}}</span>
         
        </template>

        <template #cell(locations)="items">
           
            <span class="wordBreak" v-for="(location, ind) in items.item.locations" :key="ind" @click="items.toggleDetails"> {{location.name | capitalize}}</span>
         
        </template>

        <template #cell(task_status)="items">
           
            <b-badge @click="items.toggleDetails"
              pill
              :variant="variantColor(items.item.task_status)"
              class="text-capitalize"
            >
              {{ items.item.task_status | capitalize}}
            </b-badge>
         
        </template>

        

        <!-- <template #cell(start)="items"> -->
           
            <!-- <span class="wordBreak"> -->
              <!-- {{ items.item.start | date }} -->
              <!-- {{ sitedateTime(items.item.start,siteData.pdf_date_format,'' )}}
            </span> -->
         
        <!-- </template> -->

        <!-- <template #cell(end)="items"> -->
           
            <!-- <span class="wordBreak"> -->
              <!-- {{ items.item.end | date}} -->
              <!-- {{ sitedateTime(items.item.end,siteData.pdf_date_format,'' )}}
            </span> -->
         
        <!-- </template> -->

        <!-- <template #cell(actual_started)="items"> -->
           
            <!-- <span class="wordBreak"> -->
              <!-- {{ items.item.actual_started | dateTime2 }} -->
              <!-- {{ sitedateTime2(items.item.actual_started,siteData.pdf_date_format,siteData.pdf_time_format )}} -->
            <!-- </span> -->
         
        <!-- </template> -->

        <!-- <template #cell(actual_end)="items"> -->
           
            <!-- <span class="wordBreak"> -->
              <!-- {{ items.item.actual_end | dateTime2}} -->
              <!-- {{ sitedateTime2(items.item.actual_end,siteData.pdf_date_format,siteData.pdf_time_format )}} -->
            <!-- </span> -->
         
        <!-- </template> -->

        <template #cell(actions)="items">

          <!-- <div v-if="checkPermission($route.name, 'Delete')"> -->
          <div>
            <b-link v-if="items.item.task_status == 'closed' && items.item.pdf != null" @click="openPdf(items.item.pdf)" v-b-tooltip.hover.v-warning
              title="Download Report"
              variant="outline-warning"
            >
                <feather-icon icon="DownloadIcon" class="mediumSize mr-1"  />
                
            </b-link>

            <b-link v-if="items.item.task_status == 'closed'" @click="reGenerateReport(items.item._id)" v-b-tooltip.hover.v-warning
              title="Re Generate Report"
              variant="outline-warning"
            >
                <feather-icon icon="RefreshCwIcon" class="mediumSize mr-1"  />
                
            </b-link>
          </div>

        </template>

         <template #row-details="items">
						<div class="p-1 px-2 fbDetail mb-1">
							<b-row>

							<b-col cols="12" xl="12" >
								<table class="mt-2 mt-xl-0 w-100">
									<tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Defect
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <span class="wordBreak">{{ items.item.title | capitalize}}</span>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Start Date
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <span class="wordBreak"><!-- {{ items.item.start | date }} -->
                          {{ sitedateTime(items.item.start,siteData.pdf_date_format,'' )}}
                        </span>
										</td>

										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												 End Date
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                         <span class="wordBreak"><!-- {{ items.item.end | date}} -->
                        {{ sitedateTime(items.item.end,siteData.pdf_date_format,'' )}}
                      </span>
										</td>

									</tr>

                  <tr class="mb-1">
                       <th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												 Actual Started
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                           <span class="wordBreak"><!-- {{ items.item.actual_started | dateTime2 }} -->
                            {{ sitedateTime2(items.item.actual_started,siteData.pdf_date_format,siteData.pdf_time_format )}}
                          </span>
										</td>

                     <th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												 Actual Finished
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                       <span class="wordBreak"><!-- {{ items.item.actual_end | dateTime2}} -->
                        {{ sitedateTime2(items.item.actual_end,siteData.pdf_date_format,siteData.pdf_time_format )}}
                      </span>
										</td>
                     <th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												 Completed By
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                         <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                         <span v-if="items.item.completed_by == null">N/A</span>
										</td>
                  </tr>
                  
									<tr class="mb-1">
										<th class="pb-50" style="width: 16%">
										<b-button
											v-ripple.400="'rgba(113, 102, 240, 0.15)'"
											variant="outline-secondary"
											@click="items.toggleDetails"
											size="sm"
											class="ml-1"
											>
											Hide Detail
											</b-button>
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
									</tr>
								</table>
							</b-col>

							</b-row>
						</div>

				</template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="pdf_export"
        ref="pdf_export"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Generate PDF"
        @ok="validatePDF"
        no-close-on-backdrop
      >
        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>
          <b-row>

            <b-col md="12">

              <div class="demo-inline-spacing">
                  <b-form-radio
                    class="mb-1"
                    name="some-radios"
                    value="single"
                    v-model="pdf_type"
                    
                  >
                    Single Page
                  </b-form-radio>

                  <b-form-radio
                    class="mb-1"
                    name="some-radios"
                    value="multiple"
                    v-model="pdf_type"
                  >
                    Multiple Pages
                  </b-form-radio>
              </div>

            </b-col>
              

          </b-row>
        </b-form>
      </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BCardBody,BFormSelectOption,BFormSelect,VBTooltip,BFormTextarea,BForm,BFormDatepicker,BBreadcrumb,BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { GET_API,POST_API } from "../../../store/actions.type"
import Datepicker from 'vuejs-datepicker';
import moment from "moment-timezone";
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    Datepicker,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    VueApexCharts,
    BFormTextarea,
    BAlert,
    BFormGroup,
    BForm,
    BFormDatepicker,
    BBreadcrumb,
    BFormRadio
  },
  directives: {
    'b-tooltip': VBTooltip,
    
  },
  data() {
    return {
       tableColumns: [
            { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'10px'}},
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '10%',fontSize:'10px'}},
            { key: 'locations', label: 'Location', sortable: true , thStyle:  {width: '8%',fontSize:'10px'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '8%',fontSize:'10px'}},
            // { key: 'title', label: 'Defect', sortable: true , thStyle:  {width: '11%',fontSize:'10px'}},
            { key: 'task_status', label: 'Status', sortable: true , thStyle:  {width: '7%',fontSize:'10px'}},
            //{ key: 'crew', label: 'Assigned Crew', sortable: false , thStyle:  {width: '7%',fontSize:'10px'}},
            // { key: 'completed_by', label: 'Completed By', sortable: false , thStyle:  {width: '8%',fontSize:'10px'}},
            // { key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
            // { key: 'end', label: 'End Date', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
            // { key: 'actual_started', label: 'Actual Started', sortable: true , thStyle:  {width: '7%',fontSize:'10px'}},
            // { key: 'actual_end', label: 'Actual Finished', sortable: true , thStyle:  {width: '7%',fontSize:'10px'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '9%',fontSize:'10px'}}
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        selectAll:false,
        selected:[],
        siteData:null,

        frequencies:['Reactive','Ad-Hoc','Urgent'],
        sites:[],
        clearButton:true,
        clearButton2:true,
        start:moment().tz('Asia/Singapore').toDate(),
        end:moment().tz('Asia/Singapore').toDate(),
        locations:[],
        crew:'',
        alllocations:[],
        allcrews:[],
        status:'',
        chartData: {
          listData:[],
          summaryData:[],
        },
        series:[],
        customersPie: {
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            labels: ['Open', 'In Progress', 'Closed','Validate','Failed'],

            dataLabels: {
              enabled: false,
            },
            legend: { show: false },
            stroke: {
              width: 0,
            },
            colors: [$themeColors.primary, $themeColors.warning, $themeColors.success,$themeColors.info,$themeColors.danger],
          },
        },

      form : {
        task_status : '',
        shift:'',
        start:null,
        end:null,
        supervisor:null,
        training:null,
        description:''
      },
      popshowDismissibleAlert:false,
      error_message:'',
      trainings:[],
      supervisors:[],
      highlighted : {
          dates: [
            new Date()
          ]
      },

      pdf_type:'single',
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     site:this.siteData ? this.siteData._id : null,
                     locations : this.locations,
                     crew: this.crew,
                     start:moment(this.start).format('YYYY-MM-DD'),
                     end:moment(this.end).format('YYYY-MM-DD'),
                     status:this.status,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                   },
                   api: '/api/defect-request-status-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.series =  this.$store.getters.getResults.data.series;
                        this.chartData.listData = this.$store.getters.getResults.data.listData;
                        this.chartData.summaryData = this.$store.getters.getResults.data.summaryData;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      
      this.selected = [];
      this.selectAll = false;

      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'open') {
        return 'primary';
      }else if(status == 'in_progress'){
        return 'warning'
      }else if(status == 'validate'){
        return 'info'
      }else if(status == 'failed'){
        return 'danger'
      }else{
        return 'success';
      }
    },
    

    
    selectall(e){

      this.selected = [];
      
      if (e) {
        var selected = [];

        this.items.forEach(function(item){
            selected.push(item._id);
        })

        this.selected = selected;

      }
    },
    
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              
              // if (this.sites.length == 1 ) {
              //     this.siteData = this.sites[0];
              //     this.filterTable();
              // }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);

              this.siteData = this.defaultSite(this.sites);
              this.filterTable();
              
              return this.sites;
          }
      });
    },

    customFormatter(date) {
      return moment(date).format('DD MMM YYYY');
    },
    resetData(){
      this.locations = [];
      this.crew = '';

      this.alllocations = [];
      this.allcrews =[];
      
      this.allLocations();
      this.allCrews();
      this.filterTable()
    },
    allLocations(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.siteData ? this.siteData._id : null,
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.alllocations = data;

                if (this.$route.params.locations && this.$route.params.locations != 'null') {
          
                    var locations = this.$route.params.locations.split(',');
                    //console.log(locations);

                    var selectedLocation = [];
                    
                    this.alllocations.forEach(function(item){
                        
                        if (locations.indexOf(item._id) >= 0) {
                           selectedLocation.push(item);
                        }
                    })
                    this.locations = selectedLocation;
                }


                return this.alllocations;
            }
        });
    },
    allCrews(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.siteData ? this.siteData._id : null,
             role: ['site_manager', 'site_client']
           },
           api: '/api/all-crew-by-role'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                /*var sup = null;

                for (var i = 0; i < data.length; i++) {
                  if (data[i].main_supervisor == 'yes') {
                    this.supervisor = data[i]._id;
                  }
                }*/

                //this.supervisor = this.form.supervisor ? this.form.supervisor : '';
                
                this.allcrews = data;

                /*if (this.$route.params.supervisor && this.$route.params.supervisor != 'null') {
                   this.supervisor = this.$route.params.supervisor;
                }*/

                
                return this.allcrews;
            }
        });
    },
    updateStart(){
      
      this.end = moment(this.start).tz('Asia/Singapore').toDate();
      this.filterTable();

    },
    disabledDates(){
      

      if (this.start) {

        /*var date = new Date(this.start); 
        var year = date.getFullYear();
        var month = date.getUTCMonth();
        var day = date.getDate();*/

        
        /*return {
          to: new Date(year,month , day)
        }*/
        return moment(this.start).format('YYYY-MM-DD');
        //return '2021-10-09';

      }else{


        /*var date = new Date(); 
        var year = date.getFullYear();
        var month = date.getUTCMonth();
        var day = date.getDate();*/

        //console.log(year,month,day);
        /*return {
          to: new Date(year,month , day)
        }*/
        
        return moment().format('YYYY-MM-DD');
        
      }

      
    },
    resetall(){
      
      this.siteData = null;
      this.locations = [];
      this.crew = '';
      this.start = null;
      this.end= null;
      this.status = '';
      this.alllocations = [];
      this.allcrews =[];

      this.filterTable();
    },
    openPdf(path){
      window.open(path+'?'+new Date(), '_blank');
    },

    download(type){
     /* if(this.items.length){*/
        var keyword = this.searchQuery;
        var sortBy = this.sortBy;
        var sortDirection = this.isSortDirDesc;
        var site = this.siteData ? this.siteData._id : "";
        var site_name = this.siteData ? this.siteData.site_name : "";
        
        var locations  =  [];

        for (var i = 0; i < this.locations.length; i++) {
          	locations.push(this.locations[i]._id);
        }

        var om_sites = [];

        for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
          om_sites.push(this.$store.getters.currentUser.om_sites[i]);
        }

        var role = this.$store.getters.currentUser.role;

        var crew =  this.crew;
        var start = this.start ? moment(this.start).format('YYYY-MM-DD') : "";
        var end = this.end ? moment(this.end).format('YYYY-MM-DD') : "";
        var status = this.status ;

        var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&site_name="+site_name+
        "&locations="+locations+"&crew="+crew+"&start="+start+"&end="+end+"&status="+status+"&organization="+ this.$store.getters.currentUser.organization+
		"&role="+role+"&om_sites="+om_sites.join(',')+"&pdf_type="+this.pdf_type;
        
        var baseurl = "";

        if (type == 'pdf') {
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/defect-request-status-pdf?"+params;
        }else{
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/defect-request-status-excel?"+params;
        }
        window.open(baseurl,'_blank');
    },
    
    changeMultipleStatus(status){
      if (this.selected.length > 0) {
       var msg = '';
       var msg2 = '';

       if (status == 'deleted') {
          msg = 'Are you sure you want to delete selected record?';
          msg2 = 'Records Deleted Successfully.';
       }if (status == 'active') {
          msg = 'Are you sure you want to Activate selected record?';
          msg2 = 'Records Activated Successfully.';
       }if (status == 'inactive') {

          msg = 'Are you sure you want to inactive selected record?';
          msg2 = 'Records inactivated Successfully.';
       }       
       Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: this.selected,
                          status:status
                        },
                    api:"/api/change-multiple-defect-request-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: msg2,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.selected = [];
                            this.selectAll = false;
                  
                            this.filterTable();
                          }
                      });
              }
          })
          .catch(err => {
              // An error occurred
          })   
  
      }else{
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Please select tasks.',
            showConfirmButton: false,
            timer: 1500
          })
      }
    },

    getZip(){
      var organization  = this.$store.getters.currentUser.organization;
      var keyword       = this.searchQuery;
      var sortBy        = this.sortBy;
      var sortDirection = this.isSortDirDesc;
      var site          = this.siteData ? this.siteData._id : null;
      var locations     = this.locations.length > 0 ? JSON.stringify(this.locations) : [];
      var crew          = this.supervisor && this.supervisor.length > 0 ? this.supervisor : [];
      var start         = moment(this.start).format('YYYY-MM-DD');
      var end           = moment(this.end).format('YYYY-MM-DD');
      var report        = 'service';
      var role          = this.$store.getters.currentUser.role;
      var om_sites      = this.$store.getters.currentUser.om_sites;

      var api    = '/api/zip-defect-request?';
      var params = "organization="+organization+"&keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+
                    "&site="+site+"&locations="+locations+"&crew="+crew+"&start="+start+"&end="+end+
                    "&report="+report+"&role="+role+"&om_sites="+om_sites;

      var baseurl = "";
      baseurl = process.env.VUE_APP_SERVER_URL + api + params;

      window.open(baseurl,'_blank');
    },

    zipDownload(){
        return this.$store.dispatch(POST_API, {
          data:{
                 keyword: this.searchQuery,                 
                 sortBy:this.sortBy,
                 sortDirection:this.isSortDirDesc,
                 site:this.siteData ? this.siteData._id : null,
                 locations : this.locations,
                 crew: this.supervisor,
                 start:moment(this.start).format('YYYY-MM-DD'),
                 end:moment(this.end).format('YYYY-MM-DD'),
                 report:'service',
                 role:this.$store.getters.currentUser.role,
                 om_sites:this.$store.getters.currentUser.om_sites,
              },
          api:"/api/check-zip-defect-request",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.errorAlert();
                } else {
                    var data = this.$store.getters.getResults.data;

                    if(data > 0){
                      this.getZip();
                    } else {
                      this.errorAlert();
                    }
                    // if (data) {
                    //   var link = document.createElement("a");
                    //   link.download = data.name;
                    //   link.href = data.file;
                    //   document.body.appendChild(link);
                    //   link.click();
                    //   document.body.removeChild(link);
                    // }
                  
                }
            });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:null,
        text:'Defect Report',
        active:true
      }];
      return item;
    },

    reGenerateReport(id){
      return this.$store.dispatch(POST_API, {
           data:{
             id:id
           },
           api: '/api/regenerate-defect-request-report'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                
                var data  = this.$store.getters.getResults.data;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Report Regenerated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                })
                .then( () => {
                  window.open(data + '?' + new Date(), '_blank');
                  this.filterTable();  
                })
            }
        });
    },

    openPDFPopUp(){
      this.pdf_type = 'single';
      this.$refs['pdf_export'].show();
    },

    validatePDF(e){
      //e.preventDefault();
      this.download('pdf');
      this.$refs['pdf_export'].hide();
    }
    
    

  },
  mounted(){
    this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
